class Sidebar {
  static markActive (path) {
    const $sidebar = $('.sidebar'),
          $link = $sidebar.find(`[data-path="${path}"]`);
    $link.addClass('navigation__link_active');

    $sidebar.find('[data-toggle="collapse"]').each(function(_i, el){
      var target = $(el).data('target');
      $(target).collapse('toggle');
    })
  }
}

export default Sidebar