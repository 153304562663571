import * as tocbot from 'tocbot';

import OauthActionForm from './oauth_action_form';
import Sidebar from "./sidebar";
import Jodit from 'jodit';

$( () => {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });

  if ($('.doc__toc').length) {
    tocbot.init({
      tocSelector: '.doc__toc',
      contentSelector: '.markdown',
      headingSelector: 'h1,h2,h3'
    })
  }

  let $wysiwyg = $('.wysiwyg');
  if ($wysiwyg.length) {
    $wysiwyg.each( function(i, el){
      new Jodit(el, {
        showCharsCounter: false,
        showWordsCounter: false,
        sizeLG: 400,
        sizeMD: 300,
        sizeSM: 200,
        buttons: [
          'undo',
          'redo',
          '|',
          'bold',
          'strikethrough',
          'underline',
          'italic',
          '|',
          'ul',
          'ol',
          '|',
          'image',
          'table',
          'link',
          '|',
          'align',
          'symbol'
        ]
      });
    })

  }

});

global.OauthActionForm = OauthActionForm;
global.Sidebar = Sidebar;

